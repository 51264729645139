.ant-tooltip a {
  color: #fff;
}
.ant-card-body{
  padding: 20px !important;
}

body{
  margin: 0;
}

/* Style for Load More button */
.load-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1890ff;
  text-decoration: underline;
  width: 100%; /* Set the button width to match the container */
}

/* style for sidebar menu */
.custom-menu .ant-menu-item, .custom-menu .ant-menu-submenu .ant-menu-submenu-title{
  display: grid !important;
  height: 85px;
  line-height: normal;
  align-items: center;
  justify-content: center;
  padding: 12px !important;
}
.custom-menu .ant-menu-item .ant-menu-item-icon, .custom-menu .ant-menu-submenu .ant-menu-item-icon{
  font-size: 20px !important;
  line-height: 0 !important;
}
.custom-menu .ant-menu-item .ant-menu-title-content, .custom-menu .ant-menu-submenu .ant-menu-title-content{
  margin: 0;
  opacity: 1 !important;
}
.custom-menu .ant-menu-item .ant-menu-title-content a {
  width: 100px;
  display: block;
  text-wrap: wrap;
  text-align: center;
  line-height: 18px;
}
.custom-menu li{
  margin: 0 !important;
  border-bottom: 1px solid #0000005c;
}
.ant-menu-item.custom-menu-popup{
  margin: 0 !important;
  border-bottom: 1px solid #0000005c;
}
.ant-page-header-back-button{
  padding: 10px !important;
  background: #f0f2f5 !important;
  border-radius: 35rem !important;
  border: 1px solid transparent !important;
}
.ant-page-header-back-button:hover{
  border-color:#8629ff !important;
  color: #8629ff;
}
 
